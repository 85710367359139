import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";

export default function AttachRepoForm(props: {
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const [originUrl, setOriginUrl] = useState("");
  const [originUrlError, setOriginUrlError] = React.useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = React.useState("");
  const [branch, setBranch] = useState("");
  const [branchError, setBranchError] = React.useState("");

  function createRepo() {
    
    actions.createRepo({
      name: name,
      branch_name: branch,
      url: originUrl,
      parent_folder_id: -1,
    });
    props.parentCloseHandle();
  }

  function handleOriginUrlChange(e: any) {
    if (e.target.value.trim() === "") {
      setOriginUrlError("Invalid origin URL");
    } else setOriginUrlError("");
    setOriginUrl(e.target.value);
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleBranchChange(e: any) {
    if (e.target.value.trim() === "") {
      setBranchError("Invalid branch");
    } else setBranchError("");
    setBranch(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>Attach Repo</DialogTitle>
      <DialogContent>
        <DialogContentText>Attach a git repo</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Origin URL"
          error={originUrlError !== ""}
          fullWidth
          variant="standard"
          onChange={handleOriginUrlChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name (nickname)"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Branch name"
          error={branchError !== ""}
          fullWidth
          variant="standard"
          onChange={handleBranchChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={createRepo}
          type="submit"
          variant="contained"
          color="primary"
          disabled={originUrlError !== ""}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

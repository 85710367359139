import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  setStatus as setAuthStatus,
  setOrganizationOwner, 
  setUser,
} from "../../features/auth/authSlice";
import { 
  apiChangePassword,
  apiLogin, 
  apiPasswordReset, 
  apiPasswordResetFinalize } from "../../services/userAPI";

/**
 * Login using a username and password, save this to
 * local storage, and fire off a getOrganizations action.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* login(action: ReturnType<typeof actions.login>) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(apiLogin, userPass.email, userPass.pass);

    //console.log("CCC", userResponse);

    // Set the user.
    yield put(setUser(userResponse.data));

    if (!userResponse.data.first_login) {
      // Get the organizations for this user (which triggers getWorkspaces)
      yield put(actions.getOrganizations());
    }
  } catch (e) {
    console.log(e);
    
    if (e == "Failed to fetch") {
      yield put(setAuthStatus({ status: "logged-out", error: "Cannot reach the server.  Please check your internet connection" }));
    }
    else {
      yield put(setAuthStatus({ status: "logged-out", error: "Unauthorized.  Please check your credentials" }));
    }
    return;
  }
}

export function* reset(action: ReturnType<typeof actions.reset>) {
  yield put(setAuthStatus({ status: "logged-out", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(apiPasswordReset, userPass.email);

    yield put(setAuthStatus({ status: "logged-out", error: "Password reset sent"}));

  } catch (e) {
    yield put(setAuthStatus({ status: "logged-out", error: "Could not send password reset mail.  Please contact technical support."}));
    return;
  }
}

export function* finalizeReset(action: ReturnType<typeof actions.finalizeReset>) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(apiPasswordResetFinalize, userPass.password, userPass.token);

    (<any>window).location = "/";
  } catch (e) {
    yield put(setAuthStatus({ status: "logged-out", error: "Cannot reset password.  Please contact technical support." }));
    return;
  }
}

export function* changePassword(action: ReturnType<typeof actions.changePassword>) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    // Change password
    const userResponse1 = yield call(apiChangePassword, userPass.password, "");

    // Relogin
    const userResponse2 = yield call(apiLogin, userPass.email, userPass.password);

    // Set the user.
    yield put(setUser(userResponse2.data));

    // Get the organizations for this user (which triggers getWorkspaces)
    yield put(actions.getOrganizations());
    
  } catch (e) {
    yield put(setAuthStatus({ status: "logged-out", error: "Cannot reset password.  Please contact technical support." }));
    return;
  }
}

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../../app/hooks";

import { Chart, Dash, DataTable } from "../../../types/Dash";


export default function DataTableForm(props: {
  dash: Dash;
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();


  const [name, setName] = useState("");
  const [tableName, setTableName] = useState("");

  const [nameError, setNameError] = React.useState("");
  const [tableNameError, setTableNameError] = React.useState("");

  function createDataTable() {
    const newDataTable = {
      name: name,
      table_name: tableName
    };

    actions.createDataTable(newDataTable);
    props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleTableNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setTableNameError("Invalid name");
    } else setTableNameError("");
    setTableName(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>NEW DATA TABLE</DialogTitle>
      <DialogContent>
        { /* <DialogContentText>Edit the Chart</DialogContentText> */ }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="table_name"
          label="Table Name"
          error={tableNameError !== ""}
          fullWidth
          variant="standard"
          value={tableName}
          onChange={handleTableNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={createDataTable}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={nameError !== "" || tableNameError !== ""}
        >
          Create
        </Button>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppSelector } from "../../../app/hooks";

import { ApiCall, Chart, Dash, DataTable } from "../../../types/Dash";

import { selectEditingApiCall } from "../dashEditorSlice";

export default function ApiCallList(props: {
  apiCalls: Array<ApiCall>;
  open: boolean;
  parentCloseHandle: () => void;
  parentNewApiCall: () => void;
  parentEditApiCall: (apiCall:ApiCall) => void;
  parentExecuteApiCall: (apiCall:ApiCall) => void;
}) {
  const actions = useActions();

  return (
    <Dialog open={props.open} onClose={() => {
      props.parentCloseHandle()
    }}>
      <DialogTitle>API CALLS</DialogTitle>
      <DialogContent>
        {
          props.apiCalls.map( _ => (
            <Box key={"BoxApiCall_" + _.id}>
              <Grid container spacing={3} alignItems="center">
                
                <Grid item>
                  <Typography>#{ _.id }</Typography>
                </Grid>
                <Grid item>
                  <Typography>Call "{ _.name }"</Typography>
                </Grid>
                <Grid item>
                    <Button type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => props.parentEditApiCall(_)}
                      >EDIT</Button>
                </Grid>
                <Grid item>
                  <Button type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => props.parentExecuteApiCall(_)}>EXECUTE</Button>
                </Grid>
                
              </Grid>
            </Box>
          ))
        }
      </DialogContent>  
      
      <DialogActions>
        <Button onClick={() => {
          props.parentNewApiCall();
          }} type="submit"
          variant="contained"
          color="primary">NEW</Button>
        <Button onClick={() => {
          props.parentCloseHandle();
          }}>CLOSE</Button>

      </DialogActions>

    </Dialog>
  );
}

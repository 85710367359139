import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import { selectUser } from "../../features/auth/authSlice";
import { User } from "../../types/User";
import { Repo } from "../../types/Repo";
import {
  setError as setDashError,
  setStatus as setDashStatus,
  setFileFolders,
  setFiles,
  setRepoId,
  setRepos,
  setReposMode,
  updateDashLocal,
} from "../../features/library/librarySlice";
import * as actions from "../actions";

import {
  apiCommitRepo,
  apiCreateRepo,
  apiGetFileList,
  apiGetRepos,
  apiOpenFile,
  apiPullRepo,
  apiPushRepo,
} from "../../services/RepoAPI";

export function* createRepo(
    action: ReturnType<typeof actions.createRepo>
    ) {
    yield put(setDashStatus({ status: "loading", error: undefined }));

    try {
        const userState = (yield select(selectUser)) as User;
        const createResponse = yield call(
            apiCreateRepo,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload
            );

        yield put(actions.getRepos());

    } catch (e) {
        yield put(setDashError(String(e)));
        return;
    }
}

export function* getRepos(
    action: ReturnType<typeof actions.getRepos>
    ) {
    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiGetRepos,
            userState.default_organization_id,
            userState.default_workspace_id
            );

        console.log(response.data);

        yield put(setRepos(response.data.repos));

        yield put(setDashStatus({ status: "idle", error: undefined }));
    } catch (e) {
        yield put(setDashError(String(e)));
        return;
    }
}

export function* pullRepo(
    action: ReturnType<typeof actions.pullRepo>
    ) {

    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiPullRepo,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload
            );

        yield put(setDashStatus({ status: "idle", error: "Pull complete" }));
    } catch (e) {
      yield put(setDashError(String(e)));
      return;
    }
}

export function* commitRepo(
    action: ReturnType<typeof actions.commitRepo>
    ) {
    console.log("here push");

    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiCommitRepo,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload.repoId,
            action.payload.message,
            );

        yield put(setDashStatus({ status: "idle", error: "Commit complete" }));
    } catch (e) {
      yield put(setDashError(String(e)));
      return;
    }
}

export function* pushRepo(
    action: ReturnType<typeof actions.pullRepo>
    ) {
    console.log("here push");

    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiPushRepo,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload
            );

        yield put(setDashStatus({ status: "idle", error: "Push complete" }));
    } catch (e) {
      yield put(setDashError(String(e)));
      return;
    }
}

export function* fileList(
    action: ReturnType<typeof actions.fileList>
    ) {
 
    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        yield put(setFiles([]));
        yield put(setFileFolders([]));

        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiGetFileList,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload.id,
            action.payload.folder,
            );

        yield put(setFiles(response.data.files));
        yield put(setFileFolders(response.data.folders));

        yield put(setDashStatus({ status: "idle", error: undefined }));
    } catch (e) {
      yield put(setDashError(String(e)));
      return;
    }
}

export function* openFile(
    action: ReturnType<typeof actions.openFile>
    ) {
    console.log("here");

    yield put(setDashStatus({ status: "loading", error: undefined }));
    
    try {
        const userState = (yield select(selectUser)) as User;
        const response = yield call(
            apiOpenFile,
            userState.default_organization_id,
            userState.default_workspace_id,
            action.payload.repoId,
            action.payload.file_name,
            );

        console.log(response.data.dash_id);

        //yield(put(setRepoId(-1)));
        //yield(put(setReposMode(false)));
        

        yield(put(actions.setEditingDash(response.data.dash)));

        yield put(setDashStatus({ status: "idle", error: undefined }));
    } catch (e) {
      yield put(setDashError(String(e)));
      return;
    }
}
import { blue, grey, orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { ThemeOptions } from '@mui/material/styles';

// REF: https://mui.com/customization/theming/
// What you get by default: https://mui.com/customization/default-theme/

// TODO: Work with team to make a theme: https://bareynol.github.io/mui-theme-creator/
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=006064&secondary.color=43A047

// APPLICATION: To use this in any component:
//  import { useTheme } from '@mui/styles';
//  function DeepChild() {
//    const theme = useTheme();
//    return <span>{`spacing ${theme.spacing}`}</span>;
//  }

// Additional theming properties.
declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface ThemeOptions {
    additional?: {
      highlighted?: string;
      modified?: string;
      logo?: string;
      lightBorder?: string;
    };
  }
}



export const vaingloryTheme = createTheme({
  palette: {
    //type: 'light',
    primary: {
      main: '#000000',
      light: '#FFFFFF',
      dark: '#000000',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#f5f5f5',
    },
    success: {
      main: '#009010',
    },
    text: {
      primary: '#000000',
    },
    error: {
      main: '#FF0062',
    },
    warning: {
      main: '#FF9933',
    },
    info: {
      main: '#FFFFFF',
    },
    divider: '#',
  },
  typography: {
    fontFamily: 'DMSans-Regular.ttf',
  },
  additional: {
    lightBorder: "dfdfdf",
  }
  /*
  props: {
    MuiAppBar: {
      color: 'secondary',
    },
  },*/
});

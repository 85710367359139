import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppSelector } from "../../../app/hooks";

import { ApiCall, Chart, Dash, DataTable } from "../../../types/Dash";

import { selectEditingApiCall } from "../dashEditorSlice";

export default function ApiCallForm(props: {
  dash: Dash;
  dataTableId: number;
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const apiCall = useAppSelector(selectEditingApiCall);

  //const [currentApiCall, setCurrentApiCall] = useState(props.apiCall);
  const [name, setName] = useState(""); //dataTable.name);
  const [description, setDescription] = useState(""); //dataTable.table_name);
  const [endPoint, setEndPoint] = useState("https://");
  const [defaultParameters, setDefaultParameters] = useState("");
  const [jsonPayload, setJsonPayload] = useState("");
  const [dataTableId, setDataTableId] = useState(-1);

  const [chartId, setChartId] = useState(-1);

  const [updated, setUpdated] = useState(false);

  const updateOne = useEffect(() => {
    if (apiCall !== null && !updated) {
      setName(apiCall.name);
      setDescription(apiCall.description);
      setEndPoint(apiCall.end_point);
      setDefaultParameters(apiCall.default_parameters);
      setJsonPayload(apiCall.json_payload);
      setDataTableId(apiCall.data_table_id);

      setChartId(apiCall.chart_id);

      setUpdated(true);
    }
  });

  const [nameError, setNameError] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [endPointError, setEndPointError] = React.useState("");
  const [defaultParametersError, setDefaultParametersError] = React.useState("");
  const [jsonPayloadError, setJsonPayloadError] = React.useState("");
  const [dataTableIdError, setDataTableIdError] = React.useState("");
  
  const [chartIdError, setChartIdError] = React.useState("");

  function updateApiCall() {
    if (apiCall === null) {
      const newApiCall = {
        name: name,
        description: description,
        end_point: endPoint,
        default_parameters: defaultParameters,
        json_payload: jsonPayload,

        chart_id: chartId,
        data_table_id: dataTableId,
      };

      //console.log(newApiCall);

      actions.createApiCall(newApiCall);
    }
    else {
      const updateApiCall = {
        name: name,
        description: description,
        end_point: endPoint,
        default_parameters: defaultParameters,
        json_payload: jsonPayload,

        chart_id: chartId,
        data_table_id: dataTableId,
      };

      //console.log(newApiCall);

      actions.updateApiCall(updateApiCall);
    }
    //props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleDescriptionChange(e: any) {
    setDescription(e.target.value);
  }

  function handleEndPointChange(e: any) {
    if (e.target.value.trim() === "") {
      setEndPointError("Invalid end point address");
    } else setEndPointError("");
    setEndPoint(e.target.value);
  }

  function handleDefaultParametersChange(e: any) {
    setDefaultParameters(e.target.value);
  }

  function handleJsonPayloadChange(e: any) {
    setJsonPayload(e.target.value);
  }

  function handleDataTableIdChange(e: any) {
    setDataTableId(parseInt(e.target.value));
  }

  return (
    <Dialog open={props.open} onClose={() => {
      props.parentCloseHandle()
    }}>
      <DialogTitle>Edit Api Call</DialogTitle>
      <DialogContent>
        { /* <DialogContentText>Edit the Chart</DialogContentText> */ }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="endPoint"
          label="End Point"
          error={endPointError !== ""}
          fullWidth
          variant="standard"
          value={endPoint}
          onChange={handleEndPointChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="defaultParameters"
          label="Default Parameters"
          error={defaultParametersError !== ""}
          fullWidth
          variant="standard"
          value={defaultParameters}
          onChange={handleDefaultParametersChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="jsonPayload"
          label="JSON Payload"
          error={jsonPayloadError !== ""}
          fullWidth
          variant="standard"
          value={jsonPayload}
          onChange={handleJsonPayloadChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="dataTableId"
          label="Data Table ID"
          error={dataTableIdError !== ""}
          fullWidth
          variant="standard"
          value={dataTableId}
          onChange={handleDataTableIdChange}
        />

        <Select
            label="Chart"
            value={chartId}
            onChange={(event) => setChartId(event.target.value === null || event.target.value === undefined ? -1 : Number(event.target.value))}
            fullWidth={true}
          >
          <MenuItem value={-1}>Please select the associated chart...</MenuItem>
          { props.dash.charts?.map( _ => 
            <MenuItem value={_.id} key={"MenuItemChart_" + _.id}>{_.name}</MenuItem>
          )}
        </Select>
      </DialogContent>
      
      <DialogActions>
        
        <Button
          onClick={updateApiCall}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={nameError !== "" || descriptionError !== "" || endPointError !== "" ||
            defaultParametersError !== "" || jsonPayloadError !== ""
          }
        >
          Update
        </Button> 
        <Button onClick={() => {
          props.parentCloseHandle();
          }}>Cancel</Button>

      </DialogActions>

    </Dialog>
  );
}

import { client } from './client'; 
import { User, UserUpdatePost, Workspace, WorkspaceCreateRequest,  } from '../types/User';

export function apiGetWorkspaces(organizationId: number) {
  return client.get(`/organizations/${organizationId}/workspaces`)
}

export function apiGetWorkspace(organizationId: number, workspaceId: number) {
  return client.get(`/organizations/${organizationId}/workspaces/${workspaceId}`)
}

export function apiCreateWorkspace(organizationId: number, workspace: WorkspaceCreateRequest) {
  return client.post(`/organizations/${organizationId}/workspaces`, workspace)
}

export function apiDeleteWorkspace(organizationId: number, workspace: Workspace) {
  return client.delete(`/organizations/${organizationId}/workspaces/${workspace.id}`)
}

export function apiAttachUser(organizationId: number, workspace: Workspace, user: User, role: string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/attach/${user.id}`, {
    role_type: role
  })
}

export function apiCreateUser(organizationId: number, workspace: Workspace, email: string, password: string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/users`, {
    email, password
  })
}

export function apiDetachUser(organizationId: number, workspace: Workspace, user: User) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/detach/${user.id}`, {})
}

export function apiUpdateOrganization(organizationId: number, workspace: Workspace) {
  return client.put(`/organizations/${organizationId}/workspaces/${workspace.id}`, workspace)
}

export function apiChangeUserRole(organizationId: number, workspace: Workspace, user_id: number, role:string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/change_role/${user_id}`, { role })
}

export function apiUpdateUser(organizationId: number, workspaceId: number, userId: number) {
  const userUpdate = {
    default_organization_id: organizationId, 
    default_workspace_id: workspaceId
  } as UserUpdatePost;
  
  //console.log(userUpdate);

  return client.put(`/organizations/${organizationId}/users/${userId}`, userUpdate);
}
import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectCurrentFolder,
  selectDashes,
  setError as setDashError,
  setStatus as setDashStatus,
  setDashes,
  //setImagePreview,
  updateDashLocal,
} from "../../features/library/librarySlice";
import { User } from "../../types/User";
import {
  //apiCloneDash,
  apiCreateDash,
  apiDeleteDash,
  apiGetDashImagePreview,
  apiGetDashes,
  //apiGetVariables,
  apiMoveDash,
  apiUndeleteDash,
  apiUpdateDash,
  apiUpdateDashFile,
} from "../../services/DashAPI";

import {
  apiGetDash,
} from "../../services/DashAPI";

import {
  selectEditingDash,
  setDash,
} from "../../features/editor/dashEditorSlice";
import { Chart, Dash, DashCreateRequest, DashRole, Workspace, } from "../../types/Dash";

/**
 * Get all document templates.
 *
 * @param {ReturnType<typeof actions.getDocumentTemplates>} action
 * @return {*}
 */
export function* getDashes(
  action: ReturnType<typeof actions.getDashes>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const currentFolderId = (yield select(selectCurrentFolder)) as number;

    const response = yield call(
      apiGetDashes,
      userState.default_organization_id,
      userState.default_workspace_id,
      currentFolderId,
    );

    const allDashes = response.data.dashes.map((dt:Dash) => Object.assign({}, dt, {_type: "template", dashes_roles: []}));

    (response.data.dashs_roles as Array<DashRole>).forEach(_ => {
      for (let j = 0; j < allDashes.length; j++) {
        const dash = allDashes[j];
        if (_.dash_id == dash.id) {
          dash.dashes_roles.push(_ as DashRole);
        }
      }
    });

    // Set the local slice after updating with _type: "record"
    yield put(setDashes(allDashes));

    // If you're editing a document template, pull it again.
    const selectDash = yield select(selectEditingDash);
    if (selectDash)
      yield put(actions.setEditingDash(selectDash));

    // Asynchronously get all previews.
    /*
    for(let i = 0; i < response.data.dashes.length; i++) {
      const dt = response.data.dashes[i];
      // console.log(`Getting image for ${dt.name}`);
      yield put(actions.getDashImagePreview(dt));
    }*/

    yield put(setDashStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* createDash(
  action: ReturnType<typeof actions.createDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const currentFolderId = (yield select(selectCurrentFolder)) as number;
    const userState = (yield select(selectUser)) as User;

    const data = {...action.payload} as DashCreateRequest;
    data.parent_folder_id = currentFolderId;

    const createResponse = yield call(
      apiCreateDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      data
    );

    yield put(actions.getDashes());

    const response = yield call(
      apiGetDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      createResponse.data.dash_id
    );

    yield put(setDash(response));

    // Get the document master
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* updateDash(
  action: ReturnType<typeof actions.updateDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiUpdateDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update all the document templates.
    yield put(actions.getDashes());
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* updateDashFile(
  action: ReturnType<typeof actions.updateDashFile>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiUpdateDashFile,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.dashId,
      action.payload.currentCode,
    );
    
    yield put(setDashStatus({ status: "idle", error: "File saved!" }));
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

/*
export function* getDocumentTemplateVariables(
  action: ReturnType<typeof actions.getDocumentTemplateVariables>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const variablesResponse = yield call(
      apiGetVariables,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update the local document template
    const newTemplate = Object.assign({}, action.payload, {variables: variablesResponse.data.document_variables})
    yield put(updateDocumentTemplateLocal(newTemplate));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}*/

export function* deleteDash(
  action: ReturnType<typeof actions.deleteDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiDeleteDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    console.log(response);

    // Update all the document templates.
    yield put(actions.getDashes());
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}
/*

export function* cloneDash(
  action: ReturnType<typeof actions.cloneDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiCloneDash as any,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.document_template,
      action.payload.target_workspace_id
    );
    

    // Update all the document templates.
    //yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}
*/

export function* moveDash(
  action: ReturnType<typeof actions.cloneDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiMoveDash as any,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.dash,
      action.payload.target_workspace_id
    );
    

    // Update all the document templates.
    yield put(actions.getDashes());
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}

export function* undeleteDocumentTemplate(
  action: ReturnType<typeof actions.undeleteDash>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiUndeleteDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    console.log(response);

    // Update all the document templates.
    yield put(actions.getDashes());
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}



/*
export function* getDashImagePreview(
  action: ReturnType<typeof actions.getDashImagePreview>
) {
  // Set loading.
  
  yield put(
    setImagePreview({
      documentTemplate: action.payload,
      imagePreview: { status: "loading", data: null },
    })
  ); 

  try {
    
    const userState = (yield select(selectUser)) as User;
    const imagePreviewResponse = yield call(
      apiGetDocumentTemplateImagePreview,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    yield put(
      setImagePreview({
        documentTemplate: action.payload,
        imagePreview: { status: "idle", data: imagePreviewResponse.data },
      })
    );
  } catch (e) {
    yield put(
      setImagePreview({
        dash: action.payload,
        imagePreview: { status: "failed", data: String(e) },
      })
    );
    return;
  }
}*/
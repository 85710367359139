import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SendIcon from "@mui/icons-material/Send";
import PermIdIcon from "@mui/icons-material/PermIdentitySharp";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Workspace } from "../../types/Dash";
import { Folder } from "../../types/Folder";
import { Role } from "../../types/Role";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashInfoForm from "./DashInfoForm";
import DashCloneForm from "./DashCloneForm";
import DashMoveForm from "./DashMoveForm";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
//import DashCreationForm from "../generation/DashCreationForm";

import AttachRoleForm from "./AttachRoleForm";
import { RollerShades } from "@mui/icons-material";

import {
  setFolderName,
  setRepoId,
  setUsersInRole,
} from "./librarySlice";

import { Repo } from "../../types/Repo";

/**
 * A single library item showing a Dash.
 *
 * @export
 * @param {{
 *   dash: Dash;
 * }} props
 * @return {*}
 */
export default function DashLibraryRepo(props: {
  workspaceRole: string;
  repo: Repo;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePullClicked = () => {
    actions.pullRepo(props.repo.id);
  };
  const handleCommitClicked = () => {
    const message = window.prompt("Commit message?");
    if (message !== null && message !== undefined && message != "") {
      actions.commitRepo({repoId: props.repo.id, message: message});
    }
  };
  const handlePushClicked = () => {
    actions.pushRepo(props.repo.id);
  };

  const [infoOpen, setInfoOpen] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [moveOpen, setMoveOpen] = React.useState(false);

  const [attachRoleOpen, setAttachRoleOpen] = React.useState(false);

  const [generateOpen, setGenerateOpen] = React.useState(false);

  const handleFolderClicked = () => {
    //dispatch(setUsersInRole([]));
    dispatch(setRepoId(props.repo.id));
    dispatch(setFolderName("/"));

    actions.fileList({id: props.repo.id, folder: "/"});
    //actions.getUsersInRole(props.role.id);
  };

  const handleEditClicked = () => {
    setAnchorEl(null);
    //actions.setEditingDash(props.folder);
  };
  const handleFavoriteClicked = () => {
    //setAnchorEl(null);
    //actions.favouriteDocumentTemplate(props.documentTemplate);
  };
  const handleUnfavoriteClicked = () => {
    //setAnchorEl(null);
    //actions.unfavouriteDocumentTemplate(props.documentTemplate);
  };
  const handleInfoClicked = () => {
    setAnchorEl(null);
    setInfoOpen(true);
  };

  const handleCloneToWorkspace = () => {
    setCloneOpen(true);
    setAnchorEl(null);
  };

  const handleMoveToWorkspace = () => {
    setMoveOpen(true);
    setAnchorEl(null);
  };

  const handleAttachRole = () => {
    setAttachRoleOpen(true);
    setAnchorEl(null);
  };

  const handleAttachRoleClose = () => {
    setAttachRoleOpen(false);
  };

  let itemContent = (
    <CircularProgress size="150px" style={{ padding: "10px" }} />
  );

  itemContent = (
    <PermIdIcon
        color={"primary"}
        sx={{ fontSize: 96 }}
      />
  );

  return (
    <ImageListItem
      key={"role_but"}
      sx={{
        justifyContent: "center",
        display: "flex",
        width: 125,
        height: 125,
      }}
    >
      
      <Paper
        sx={{
          background: "#CCCCCC",
          justifyContent: "center",
          display: "flex",
          width: 125,
          height: 125,
        }}
      >
        
        
        {/* <Badge
          badgeContent={""}
          color={"success"}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        >
          
      </Badge> */}
        <IconButton
            data-index={"role_but_1"}
            key={"role_but_2"}
            onClick={(e) => handleFolderClicked()}
            
          >
            
            {itemContent}
          </IconButton>
      </Paper>
      <ImageListItemBar
        title={props.repo.name}
        position="below"
        actionIcon={
          <>
            <IconButton
              aria-label={props.repo.name}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        }
        
      >

        
        </ImageListItemBar>
      
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}

        >
        <MenuItem onClick={handlePullClicked}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Pull...</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCommitClicked}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Commit...</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePushClicked}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Push...</ListItemText>
        </MenuItem>
      </Menu>
    </ImageListItem>
  );
}

import React, { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import FolderIcon from "@mui/icons-material/Folder";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import NoteIcon from "@mui/icons-material/Note";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircle from "@mui/icons-material/AccountCircle";

import {
  Workspace,
} from "../../types/User";

import logo from '../../logo-black.png';

import useActions, { useAppSelector } from "../../app/hooks";
import { selectAuth, selectCurrentWorkspace, selectCurrentWorkspaceRole } from "../auth/authSlice";

import { useAppDispatch } from "../../app/hooks";
import { logout } from "../auth/authSlice";
import {
  clearDash,
  selectEditingDash,
} from "../editor/dashEditorSlice";
import {
  selectDashes,
  selectRoles,
} from "../library/librarySlice";
import { OrganizationsMenu } from "./OrganizationsMenu";
import { CreateMenu } from "./CreateMenu";
import { getDashes } from "../../app/sagas/dashes";
import ShowAdminUsers from "./ShowAdminUsers";

export function HeaderMenu() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const editingDash = useAppSelector(selectEditingDash);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const allDashes = useAppSelector(selectDashes);
  const allRoles = useAppSelector(selectRoles);
  const actions = useActions();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [adminUsers, setAdminUsers] = React.useState<boolean>(
    false
  );

  const handleGotoLibrary = () => {
    dispatch(clearDash());
    actions.getDashes();
    actions.getFolders();
    actions.getRoles();
    actions.getRepos();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenCreateOrgUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAdminUsers(true);
    setAnchorElUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutUserMenu = () => {
    dispatch(logout());
    setAnchorElUser(null);
  };

  return (
    // Note the z-index gracefully puts this above all the drawers, the nominal operation is a little strange.
    // REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#000000' }}
    >
      <Toolbar>
        <img src={logo} width="100px" style={{ marginRight: "20px" }} alt="vaingloryLogo"/>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
        >
          LOGO
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button
            startIcon={<FolderIcon fontSize="large" />}
            sx={{ color: "white" }}
            onClick={handleGotoLibrary}
          >
            WORKBOOKS
          </Button>
          { (currentWorkspaceRole == "ADMIN") && authState.workspaces !== undefined &&
            <CreateMenu workspaces={authState.workspaces as Array<Workspace>} workspaceId={authState.user?.default_workspace_id}></CreateMenu>
          }
        
        </Box>

        {/* Right side */}
        <Typography sx={{mx: 2}}>{currentWorkspaceRole}</Typography>
    
        <OrganizationsMenu
          user={authState.user}
          organizations={authState.organizations}
          workspaces={authState.workspaces}
          roles={allRoles}
          
        ></OrganizationsMenu>
        <Box sx={{ flexGrow: 0 }}>
          { /* <Tooltip title="Help" sx={{ color: "#FFFFFF" }}>
            <IconButton>
              <HelpIcon></HelpIcon>
            </IconButton>
        </Tooltip> */ }

          <Tooltip title="User Account" style={{ marginLeft: "10px" }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: "#FFFFFF" }}>
              <AccountCircle />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">User Profile</Typography>
          </MenuItem> */}
            <MenuItem key="create_admin_user" onClick={handleOpenCreateOrgUserMenu}>
              <Typography textAlign="center">Create Admin User</Typography>
            </MenuItem>
            <MenuItem key="logout" onClick={handleLogoutUserMenu}>
              <Typography textAlign="center">Log Out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      {adminUsers ? <ShowAdminUsers closeDialogHandler={() => {
        setAdminUsers(false);
      }}/> : null }
    </AppBar>
  );
}

import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { useDrop } from "react-dnd";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
//import { ChartEditorHeaderMenu } from "./header/EditorHeaderMenu";
import { ChartEditor } from "./document/ChartEditor";
import { ChartAdder } from "./document/ChartAdder";

import {
  selectEditingDash,
  selectPageNo,
  selectSelected,
  selectViewCodeState,
  setViewCodeState,
} from "./dashEditorSlice";
import { selectAuth, selectCurrentWorkspaceRole } from "../auth/authSlice";
import { Chart, Dash } from "../../types/Dash";
import { selectFocusChart } from "../library/librarySlice";
//import { clearPreview, selectDocumentPreview } from "../library/librarySlice";
import PreviewModal from "../preview/PreviewModal";
import { selectPreferences } from "./header/preferencesSlice";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { AddCircleOutline } from "@mui/icons-material";

import CodeMirror from '@uiw/react-codemirror';
import { rust } from '@codemirror/lang-rust';

/**
 * The main document editor. When a DocumentTemplate is set
 * this is created to allow it to be edited.
 *
 * @export
 * @return {*}
 */
export default function DashEditor(props: {
  from_outside: boolean,
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const dash = useAppSelector(selectEditingDash);
  const pageNo = useAppSelector(selectPageNo);
  const viewCodeState = useAppSelector(selectViewCodeState);
  const auth = useAppSelector(selectAuth);

  const focusChart = useAppSelector(selectFocusChart);

  const [currentCode, setCurrentCode] = useState(dash.current_code === null ? "" : dash.current_code);

  const viewPreferences = useAppSelector(selectPreferences);
  const parameters = dash.parameters !== null && dash.parameters !== undefined ? dash.parameters : [];
  //const documentPreview = useAppSelector(selectDocumentPreview);
  const selectedComponents = useAppSelector(selectSelected);

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);

  const isEditor = currentWorkspaceRole == "ADMIN";

  const chartRefs: { [id: number] : HTMLDivElement; } = {};

  function handleCurrentCodeChange(value:string) {
    setCurrentCode(value);
  }

  const scrollEffect = useEffect( () => {
    if (focusChart !== null && chartRefs[focusChart.id] !== null && chartRefs[focusChart.id] !== undefined) {
      chartRefs[focusChart.id].scrollIntoView();
    }
  }, [focusChart]);

  function handleAddNavigationClicked(e: any) {
    // Get a navigation name
    const name = window.prompt("Name of navigation?");

    // Figure out the page numbers
    let pageNo = 1;
    let maxPageNo = 0;
    dash.navigations?.forEach(_ => {
      if (_.page_no > maxPageNo) {
        maxPageNo = _.page_no;
      }
    });

    if (maxPageNo > 0) {
      pageNo = maxPageNo + 1;
    }

    if (name !== null && name !== undefined) {


      actions.createNavigation({
        parent_id: -1,
        name: name,
        page_no: pageNo,
        valid_roles: ""
      });
    }
  }

  function handleSetPageClicked(e: any, pageNo:number) {
    actions.setPageNo(pageNo);
  }

  /*
  function handleDeleteNavigationClicked(e: any, id:number) {

  }

  function handleEditNavigationClicked(e: any, id:number) {
    
  }*/

  const [pageRef, setPageRef] = React.useState<null | HTMLDivElement>(null);

  return (
    <Box>
      <AppBar></AppBar>
      <Box sx={{ px: 2 }}>
         {/* <DashHeaderMenu
          organizationId={auth.user!.default_organization_id!}
          workspaceId={auth.user!.default_workspace_id!}
          dash={dash}
  ></DashHeaderMenu> */}
      </Box>

      {/* Big page view */}
      <Box
        sx={{
          bgcolor: grey[400],
          px: 5,
          display: "flex",
          flexDirection: "column",
          height: "87vh",
          overflow: "hidden",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}
        ref={ (ref:HTMLDivElement) => setPageRef(ref) }
      >
        {/* Centered box for page  ref={ drop } */}
        <Box
          
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {/* The white board */}
          <Paper elevation={3} sx={{ my: 1, width: "850px" }}>
            {/* The sections, sorted already by component_order */}

            {/* Navigation block atop a dashboard */}
            { dash !== null &&
              <Box sx={{ padding: "10px"}}>
                
                { !props.from_outside && <Typography sx={{marginBottom: "10px" }}><strong>WORKBOOK:</strong> { dash.name }</Typography> }
                { props.from_outside && <Typography sx={{marginBottom: "10px", fontSize: "1.3em", fontWeight: "bold" }}>{ dash.name }</Typography> }
                <Box>
                  {
                    dash.navigations?.map(_ => (
                      <Button key={"nav_" + _.id}variant={_.page_no == pageNo ? "contained" : "outlined"} sx={{marginRight: "5px" }}
                        onClick={(e) => handleSetPageClicked(e, _.page_no)}>
                        { _.name }
                      </Button>
                    ))
                  }
                  { !props.from_outside && isEditor && <IconButton
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleAddNavigationClicked}
                  >
                    <AddCircleOutline/>
                  </IconButton> }
                  { dash.repo_id !== null && <Box>
                    <Button key={"but_workbook_code"} variant={viewCodeState == 1 ? "contained" : "outlined"} sx={{marginRight: "5px" }}
                      onClick={(e) => dispatch(setViewCodeState(1))}>
                      WORKBOOK
                    </Button>
                    <Button key={"but_proposed_code"} variant={viewCodeState == 2 ? "contained" : "outlined"} sx={{marginRight: "5px" }}
                      onClick={(e) => dispatch(setViewCodeState(2))}>
                      PROPOSED CODE
                    </Button>
                    <Button key={"but_current_code"} variant={viewCodeState == 3 ? "contained" : "outlined"}
                      onClick={(e) => dispatch(setViewCodeState(3))}>
                      CURRENT CODE
                    </Button>
                    { viewCodeState == 3 && <Button key={"but_update_current_code"} variant={ "contained" }
                      onClick={(e) => actions.updateDashFile({ dashId: dash.id, currentCode: currentCode})}>
                      SAVE
                    </Button> }
                  </Box> }
                </Box>
              </Box> }
            { viewCodeState == 1 && <Box>
              { dash !== null ? dash!.charts!.filter(_ => _.page_no == pageNo).map(
                (chart: Chart, index) => (
                  <ChartEditor
                    from_outside={props.from_outside}
                    key={chart.id}
                    organizationId={auth.user!.default_organization_id!}
                    workspaceId={auth.user!.default_workspace_id!}
                    dash={dash}
                    chart={chart}
                    viewPreferences={viewPreferences}
                    isSelected={selectedComponents.chart?.id === chart.id}
                    is_editor={isEditor}
                    set_ref={ (ref:HTMLDivElement) => chartRefs[chart.id] = ref }
                    parameters={parameters}
                  />
                )
              ) : ""}
              { /*isOver ? (
                <Box>
                  <Typography variant="h1">{<Skeleton />}</Typography>
                  <Typography variant="h3">{<Skeleton />}</Typography>
                  <Typography variant="h3">{<Skeleton />}</Typography>
                </Box>
              ) : null */}

              { isEditor && <ChartAdder
                organizationId={auth.user!.default_organization_id!}
                workspaceId={auth.user!.default_workspace_id!}
                dash={dash}
                pageNo={pageNo}
              ></ChartAdder> }
            </Box> }

            { viewCodeState == 3 && 
              <Box>
                <CodeMirror value={currentCode} height="70vh" 
                  extensions={[rust()]}
                  onChange={handleCurrentCodeChange}
                  style={{borderTop: "1px solid #000000", borderBottom: "1px solid #000000"}} /> 
              </Box>}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppSelector } from "../../../app/hooks";

import { Chart, Dash, DataTable } from "../../../types/Dash";

import { selectEditingDataTable } from "../dashEditorSlice";

export default function DataTableEditor(props: {
  dash: Dash;
  open: boolean;
  parentCloseHandle: () => void;
  parentShowApiCalls: () => void;
}) {
  const actions = useActions();

  const dataTable = useAppSelector(selectEditingDataTable);

  const [currentDataTable, setCurrentDataTable] = useState(dataTable);
  const [name, setName] = useState(""); //dataTable.name);
  const [tableName, setTableName] = useState(""); //dataTable.table_name);
  const [newColumnType, setNewColumnType] = useState("bigint");
  const [newColumnName, setNewColumnName] = useState("");

  const updateOne = useEffect(() => {
    if (currentDataTable !== dataTable && dataTable !== null) {
      setName(dataTable.name);
      setTableName(dataTable.table_name);
      setCurrentDataTable(dataTable);
    }
  });

  const [nameError, setNameError] = React.useState("");
  const [tableNameError, setTableNameError] = React.useState("");
  const [columnNameError, setColumnNameError] = React.useState("");

  function updateDataTable() {
    const newDataTable = {
      name: name,
      table_name: tableName
    };

    //actions.createDataTable(newDataTable);
    //props.parentCloseHandle();
  }

  function addTableColumn() {
    const newColumn = {
      name: newColumnName,
      column_type: newColumnType
    };

    actions.createDataTableColumn(newColumn);
    //props.parentCloseHandle();
  }

  function apiCalls() {
    props.parentShowApiCalls();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleTableNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setTableNameError("Invalid name");
    } else setTableNameError("");
    setTableName(e.target.value);
  }

  function handleColumnNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setColumnNameError("Invalid name");
    } else setColumnNameError("");
    setNewColumnName(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => {
      props.parentCloseHandle()
    }}>
      <DialogTitle>DATA TABLE EDITOR</DialogTitle>
      <DialogContent>
        { /* <DialogContentText>Edit the Chart</DialogContentText> */ }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="table_name"
          label="Table Name"
          error={tableNameError !== ""}
          fullWidth
          variant="standard"
          value={tableName}
          onChange={handleTableNameChange}
        />
        { currentDataTable !== null && <Box>
          <Typography sx={{marginTop: "20px", marginBottom: "20px"}}><strong>COLUMNS</strong></Typography>
          {
            currentDataTable.columns?.map( _ => (
              <Box key={"BoxCurrentDataTable_" + _.column_name}>
                <Typography>"{ _.column_name }" DATA TYPE { _.data_type }.  NULLABLE { _.is_nullable }</Typography>
              </Box>
            ))
          }
          <Typography sx={{marginTop: "20px", marginBottom: "20px"}}><strong>CREATE COLUMN</strong></Typography>
          <TextField
            autoFocus
            margin="dense"
            id="col_name"
            label="New Column Name"
            error={columnNameError !== ""}
            fullWidth
            variant="standard"
            value={newColumnName}
            onChange={handleColumnNameChange}
          />
          <Select
                label="Column Type"
                value={newColumnType}
                onChange={(event) => setNewColumnType(event.target.value)}
                fullWidth={true}
              >
              <MenuItem value="bigint">Big Integer</MenuItem>
              <MenuItem value="double">Double</MenuItem>
              <MenuItem value="varchar">Variable Character</MenuItem>
            </Select>
        </Box> }
      </DialogContent>
      
      <DialogActions>
        { dataTable !== null &&
        <Button
          onClick={updateDataTable}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={nameError !== "" || tableNameError !== ""}
        >
          Update
        </Button> }

        <Button
          onClick={addTableColumn}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={columnNameError !== ""}
        >
          ADD COLUMN
        </Button>
        <Button
          onClick={apiCalls}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={columnNameError !== ""}
        >
          API CALLS
        </Button>
        <Button onClick={() => {
          props.parentCloseHandle();
          }}>Cancel</Button>
      </DialogActions>

    </Dialog>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearPreview,
  selectFileFolders,
  selectFiles,
  selectFilteredDashes,
  selectFilteredFileFolders,
  selectFilteredFiles,
  selectFilteredFolders,
  selectFolderName,
  selectFolderStack,
  selectLibrary,
  selectRepoFolder,
  selectRepoId,
  selectRepos,
  selectReposMode,
  selectRoleId,
  selectRoles,
  selectRolesMode,
  selectUsersInRole,
  setError,
} from "./librarySlice";
import ImageList from "@mui/material/ImageList";
import Toolbar from "@mui/material/Toolbar";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import DashLibraryItem from "./DashLibraryItem";
import DashLibraryFolder from "./DashLibraryFolder";
import DashLibraryRolesSpecial from "./DashLibraryRolesSpecial";
import DashLibraryReposSpecial from "./DashLibraryReposSpecial";
import DashLibraryRole from "./DashLibraryRole";
import DashLibraryRepo from "./DashLibraryRepo";
import DashLibraryFile from "./DashLibraryFile";
import DashLibraryFileFolder from "./DashLibraryFileFolder";
import DashLibraryUser from "./DashLibraryUser";
//import { selectDashPreview } from "./librarySlice";
import PreviewModal from "../preview/PreviewModal";
import { selectCurrentWorkspace, selectCurrentWorkspaceRole, selectWorkspaces } from "../auth/authSlice";
import { LibraryHeaderMenu } from "./header/LibraryHeaderMenu";
import { Dash } from "../../types/Dash";
import { Workspace } from "../../types/User";
import { Folder } from "../../types/Folder";

export default function Library() {
  const librarySlice = useAppSelector(selectLibrary);
  const filteredDashes = useAppSelector(
    selectFilteredDashes
  );
  const filteredFolders = useAppSelector(
    selectFilteredFolders
  );
  const rolesMode = useAppSelector(
    selectRolesMode
  );
  const reposMode = useAppSelector(
    selectReposMode
  );
  const viewRoleId = useAppSelector(
    selectRoleId
  );
  const viewRepoId = useAppSelector(
    selectRepoId
  );
  const folderStack = useAppSelector(
    selectFolderStack
  );
  const dispatch = useAppDispatch();
  //const documentPreview = useAppSelector(selectDocumentPreview);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const workspaces = useAppSelector(selectWorkspaces);
  const roles = useAppSelector(selectRoles);
  const repos = useAppSelector(selectRepos);
  const files = useAppSelector(selectFilteredFiles);
  const fileFolders = useAppSelector(selectFilteredFileFolders);
  const folderName = useAppSelector(selectFolderName);
  const usersInRole = useAppSelector(selectUsersInRole);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace) as Workspace;

  function handlePreviewClose() {
    dispatch(clearPreview());
  }

  const roleFilteredDashes = filteredDashes.filter(_ => {
      return true;
  });

  const roleFilteredFolders = filteredFolders.filter(_ => {
    return true;
  });

  //console.log("A", fileFolders);

  const content = (
    <Box sx={{width: "100%"}}>
      <ImageList cols={reposMode ? 8 : 6}>
        { !rolesMode && !reposMode && currentWorkspaceRole == "ADMIN" && folderStack.length == 0 && 
          <DashLibraryRolesSpecial
          key={"roles_special_1"}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
        />
        }
        { !rolesMode && !reposMode && currentWorkspaceRole == "ADMIN" && folderStack.length == 0 && 
          <DashLibraryReposSpecial
          key={"roles_special_2"}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
        />
        }
        { !rolesMode && !reposMode && roleFilteredFolders.map((folder) => (
            <DashLibraryFolder
              key={folder.id}
              folder={folder as Folder}
              workspaceRole={currentWorkspaceRole}
              workspaces={workspaces}
              currentWorkspace={currentWorkspace}
              favourite={false}
              roles={roles}
            />)) }

        { !rolesMode && !reposMode && roleFilteredDashes.map((dash) => (
            <DashLibraryItem
              key={dash.id}
              dash={dash as Dash}
              workspaceRole={currentWorkspaceRole}
              workspaces={workspaces}
              currentWorkspace={currentWorkspace}
              favourite={false}
              roles={roles}
            />)) }
        { rolesMode && viewRoleId < 0 && roles.map((role) => (
          <DashLibraryRole
          key={"role_" + role.id}
          role={role}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
        />
        ))}
        { rolesMode && viewRoleId > 0 && usersInRole.map((user) => (
          <DashLibraryUser
          key={"user_" + user.id}
          user={user}
          roleId={viewRoleId}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
        />
        ))}

        { reposMode && viewRepoId < 0 && currentWorkspace.repos !== null && repos.map((repo) => (
          <DashLibraryRepo
          key={"repo_" + repo.id}
          repo={repo}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
        />
        ))}
      
      </ImageList>
      { viewRepoId > 0 && reposMode && <List sx={{ width: '100%', maxWidth: 360 }}>
        {  fileFolders.map((fileFolder) => (
          <DashLibraryFileFolder
          key={"repo_folder_" + fileFolder}
          file_folder={fileFolder}
          repoId={viewRepoId}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
          folderName={folderName}
        />
        ))}

        {  files.map((file) => (
          <DashLibraryFile
          key={"repo_file_" + file}
          file={file}
          repoId={viewRepoId}
          workspaceRole={currentWorkspaceRole}
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
          folderName={folderName}
        />
        ))}
      </List> }
      
    </Box>
  );

  // TODO: https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1
  return (
    <Box sx={{ bgcolor: grey[200], px: 5, py: 1, height: "100vh" }}>
      {/* Slightly awkward way to pad the top as per MUI guidelines */}
      <Toolbar />
      <LibraryHeaderMenu />
      {librarySlice.error !== undefined ? (
        <Alert
          severity="error"
          onClose={() => {
            dispatch(setError(undefined));
          }}
        >
          {librarySlice.error}
        </Alert>
      ) : null}
      <Box sx={{ bgcolor: grey[200], 
        flexGrow: 2, 
        width: "100%",            display: "flex",
        flexDirection: "column",
        // height: "87vh",
        overflow: "hidden",
        // overflowY: "scroll",
}}>
        {/* <Box
          sx={{
            bgcolor: grey[400],
            px: 5,
            display: "flex",
            flexDirection: "column",
            height: "87vh",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        > */}
          {content}
        {/* </Box> */}
      </Box>
      { /*documentPreview != null ? (
        <PreviewModal
          setParentOpenHandler={handlePreviewClose}
          preview={documentPreview!}
        ></PreviewModal>
      ) : */ null}
    </Box>
  );
}

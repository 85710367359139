import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppSelector } from "../../../app/hooks";

import { Chart, Dash, DataTable } from "../../../types/Dash";

import { selectEditingDataTable } from "../dashEditorSlice";


export default function DataTableInsert(props: {
  dash: Dash;
  open: boolean;
  dataTableId: number;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const dataTable = useAppSelector(selectEditingDataTable);

  const [values, setValues] = useState({});

  function createRow() {
    const newRow = {
      values: {...values}
    };

    actions.createDataTableRow(newRow);
    props.parentCloseHandle();

    setValues({});
  }

  function handleValueChange(e: any, parmName: string, typeName:string) {

    if (typeName.includes("bigint")) {
      const v:any = values;
      v[parmName] = e.target.value;
      setValues(v);
    }
    else if (typeName.includes("double")) {
      const v:any = values;
      v[parmName] = e.target.value;
      setValues(v);
    }
    else if (typeName.includes("character")) {
      const v:any = values;
      v[parmName] = "'" + e.target.value + "'";
      setValues(v);
    }
  }

  // error={nameError !== ""}
  // disabled={nameError !== "" || tableNameError !== ""}

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>New Row</DialogTitle>
      <DialogContent>
      
        { dataTable !== null && <Box>
            <Typography sx={{marginTop: "20px", marginBottom: "20px"}}><strong>VALUES PER COLUMN</strong></Typography>
            {
              dataTable.columns?.filter(_ => _.column_name != "id").map( _ => (
                <TextField
                  key={"TextFieldDataTable__" + _.column_name}
                  autoFocus
                  margin="dense"
                  id={"in_" + _.column_name}
                  label={ _.column_name + " DATA TYPE " + _.data_type + " NULLABLE " + _.is_nullable }
                  fullWidth
                  variant="standard"
                  value={(values as any)[_.column_name]}
                  onChange={(e) => handleValueChange(e, _.column_name, _.data_type)}
                />
              ))
              }
        </Box> }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={createRow}
          type="submit"
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

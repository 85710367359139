import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SendIcon from "@mui/icons-material/Send";
import CloudIcon from "@mui/icons-material/Cloud";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Workspace } from "../../types/Dash";
import { Folder } from "../../types/Folder";
import { Role } from "../../types/Role";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashInfoForm from "./DashInfoForm";
import DashCloneForm from "./DashCloneForm";
import DashMoveForm from "./DashMoveForm";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
//import DashCreationForm from "../generation/DashCreationForm";

import AttachRoleForm from "./AttachRoleForm";

import {
  setReposMode,
} from "./librarySlice";

/**
 * A single library item showing a Dash.
 *
 * @export
 * @param {{
 *   dash: Dash;
 * }} props
 * @return {*}
 */
export default function DashLibraryReposSpecial(props: {
  workspaceRole: string;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //If the preview is not loaded, load it.
  // if (
  //   !props.documentTemplate.imagePreview ||
  //   (!props.documentTemplate.imagePreview.data === null &&
  //     props.documentTemplate.imagePreview.status === "idle")
  // ) {
  //   console.log("Going to load preview");
  //   // // Asynchronously get all previews.
  //   actions.getDocumentImagePreview(props.documentTemplate);
  //   }
  // });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleFolderClicked = () => {
    dispatch(setReposMode(true));
  };

  let itemContent = (
    <CircularProgress size="150px" style={{ padding: "10px" }} />
  );

  itemContent = (
    <CloudIcon
        color={"primary"}
        sx={{ fontSize: 96 }}
      />
  );

  return (
    <ImageListItem
      key={"role_but"}
      sx={{
        justifyContent: "center",
        display: "flex",
        width: 200,
        height: 250,
      }}
    >
      
      <Paper
        sx={{
          background: "#CCCCCC",
          justifyContent: "center",
          display: "flex",
          width: 200,
          height: 250,
        }}
      >
        
        
        <IconButton
          data-index={"role_but_1"}
          key={"role_but_2"}
          onClick={(e) => handleFolderClicked()}
          
        >
          
          {itemContent}
        </IconButton>
      </Paper>
      <ImageListItemBar
        sx={{ "& .MuiImageListItemBar-title": { fontWeight: "bold" }, }}
        title={"CODE REPOSITORIES"}
        position="below"
        
        
      >
        {/* actionIcon={
          <>
            <IconButton
              aria-label={`all roles`}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        } */}
        
        </ImageListItemBar>

      
      
    </ImageListItem>
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  ApiCall,
  Chart,
  Dash,
  DataTable,
  Navigation,
  Parameter,
  Workspace,
} from "../../types/Dash";

export interface DocumentState {
  dash: Dash | null;
  dataTable: DataTable | null;
  apiCall: ApiCall | null;

  page_no: number;

  selectedChart: Chart | null;

  viewCodeState: number;

  documentPreview: any;

  status: "idle" | "loading" | "failed";
  error: string | undefined;
}

const initialState: DocumentState = {
  dash: null,
  dataTable: null,
  apiCall: null,

  page_no: 1,
  
  selectedChart: null,

  viewCodeState: 1,
  
  documentPreview: null,
  status: "idle",
  error: undefined,
};

export const dashEditorSlice = createSlice({
  name: "editor",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearDash: (state) => {
      state.dash = null;
    },
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setDash: (state, action: PayloadAction<Dash>) => {
      state.dash = action.payload;
    },
    setDataTable: (state, action: PayloadAction<DataTable | null>) => {
      state.dataTable = action.payload;
    },
    setApiCall: (state, action: PayloadAction<ApiCall | null>) => {
      state.apiCall = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.page_no = action.payload;
    },
    setViewCodeState: (state, action: PayloadAction<number>) => {
      state.viewCodeState = action.payload;
    },
    addChartLocal: (state, action: PayloadAction<Chart>) => {
      const chart = {...action.payload};

      state.dash!.charts!.push(chart);
    },
    addNavigationLocal: (state, action: PayloadAction<Navigation>) => {
      const navigation = {...action.payload};

      state.dash!.navigations!.push(navigation);
    },
    addDataTableLocal: (state, action: PayloadAction<DataTable>) => {
      const dataTable = {...action.payload};

      state.dash!.data_tables!.push(dataTable);
    },
    addApiCallLocal: (state, action: PayloadAction<ApiCall>) => {
      const callApi = {...action.payload};

      state.dash!.api_calls!.push(callApi);
    },
    addParameterLocal: (state, action: PayloadAction<Parameter>) => {
      const parameter = {...action.payload};

      state.dash!.parameters!.push(parameter);
    },
    updateChartLocal: (state, action: PayloadAction<Chart>) => {
      
      const index = state.dash!.charts!.findIndex(
        (sec) => sec.id === action.payload.id
      );
      if (index !== undefined && index >= 0)
        state.dash!.charts![index] = action.payload;
      else
        console.error(
          `Cannot find chart with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },
    deleteChartLocal: (state, action: PayloadAction<Chart>) => {
      //console.log("In local delete!");
      const index = state.dash?.charts?.findIndex(
        (s) => s.id === action.payload.id
      );
      //console.log("Deleting chart: " + index);
      if (index !== undefined && index >= 0)
        state.dash?.charts?.splice(index, 1);
      else
        console.error(
          `Cannot find chart with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },
    setSelectedComponent: (
      state,
      action: PayloadAction<{
        selectedChart: Chart | null;
      }>
    ) => {
      state.selectedChart = action.payload.selectedChart;
    },
    clearSelectedComponent: (
      state,
    ) => {
      state.selectedChart = null;
    },
    clearPreview: (state) => {
      state.documentPreview = null;
    },
  }
});

export const {
  
  setStatus,
  setError,
  clearDash,
  setDash,
  setDataTable,
  setApiCall,
  setPageNo,
  setViewCodeState,
  setSelectedComponent,
  clearSelectedComponent,
  addChartLocal,
  addNavigationLocal,
  addDataTableLocal,
  addApiCallLocal,
  addParameterLocal,
  updateChartLocal,
  deleteChartLocal,
  
} = dashEditorSlice.actions;

//Selectors

export const selectDocumentState = (state: RootState) => state.document!;
export const selectEditingDash = (state: RootState) => 
  {
    //console.log("A", state.document.dash);
    return state.document.dash!;
  };
export const selectEditingDataTable = (state: RootState) => 
{
  //console.log("A", state.document.dash);
  return state.document.dataTable!;
};

export const selectEditingApiCall = (state: RootState) => 
{
  //console.log("A", state.document.dash);
  return state.document.apiCall!;
};

export const selectPageNo = (state: RootState) => state.document!.page_no;

// Defensive select of charts
export const selectCharts = (state: RootState) =>
  state.document.dash !== null && state.document.dash !== undefined ?
  (state.document.dash.charts !== null && state.document.dash.charts !== undefined ? state.document.dash.charts : []) : [];

export const selectSelected = (state: RootState) => {
  return {
    chart: state.document.selectedChart
  };
};
//export const selectDocumentPreview = (state: RootState) =>
//  state.dash.documentPreview;

export const selectViewCodeState = (state: RootState) => state.document!.viewCodeState;

export default dashEditorSlice.reducer;



import { select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import { selectUser } from "../../features/auth/authSlice";
import {
  addApiCallLocal,
  addChartLocal,
  addDataTableLocal,
  addNavigationLocal,
  addParameterLocal,
  deleteChartLocal,
  selectEditingApiCall,
  selectEditingDash,
  selectEditingDataTable,
  setDash,
  setDataTable,
  setError,
  setPageNo,
  setStatus,
  updateChartLocal,
} from "../../features/editor/dashEditorSlice";
import { User } from "../../types/User";
import {
  apiAttachChart,
  apiCreateApiCall,
  apiCreateChart,
  apiCreateDataTable,
  apiCreateDataTableColumn,
  apiCreateDataTableRow,
  apiCreateNavigation,
  apiCreateParameter,
  apiDeleteChart,
  apiExecuteApiCall,
  apiExecuteChartCode,
  apiGetChartData,
  apiGetDash,
  apiGetDataTable,
  apiUpdateApiCall,
  apiUpdateChart,
  apiUpdateChartCode,
} from "../../services/DashAPI";
import {
  ApiCall,
  Chart,
  Dash,
  DataTable,
} from "../../types/Dash";

import { idText, isConstructorDeclaration } from "typescript";

export function* setEditingDash(
  action: ReturnType<typeof actions.setEditingDash>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    //console.log("B");

    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetDash,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id
    );


    //console.log("AAA", response);

    // Get the dashboard and details
    

    // Get all associated data
    //02OCT
    //console.log("AAAAAA", response);


    if (response.charts !== null && response.charts !== undefined) {
      //(response.charts as Array<Chart>).forEach(_ => {
      for (let i = 0; i < response.charts.length; i++) {
        const dataSource = response.charts[i].data_source;

        if (dataSource.length > 1) {
          const dataSourceJson = JSON.parse(dataSource);

          if (dataSourceJson.data_type !== undefined) {
            if (dataSourceJson.data_type == "datatable" && dataSourceJson.id !== undefined) {
              const dtResponse = yield call(
                apiGetChartData,
                userState.default_organization_id,
                action.payload.id,
                dataSourceJson.id,
                dataSourceJson.x,
                dataSourceJson.y,
              );

              
              response.charts[i].data = dtResponse.data.data;

              console.log("DT", response);
            }
          }
        }
      }
    }

    yield put(setDash(response));
    yield put(setPageNo(1));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.log(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* setEditingDataTable(
  action: ReturnType<typeof actions.setEditingDataTable>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    yield put(setDataTable(null));

    //console.log("B");

    const userState = (yield select(selectUser)) as User;

    const edittingDash = (yield select(selectEditingDash)) as Dash;

    const response = yield call(
      apiGetDataTable,
      userState.default_organization_id,
      userState.default_workspace_id,
      edittingDash.id,
      action.payload
    );

    // Send the data table to the frontend
    yield put(setDataTable(response));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.log(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* setPageNoSaga(
  action: ReturnType<typeof actions.setPageNo>
) {
  yield put(setPageNo(action.payload));
}

export function* createChart(
  action: ReturnType<typeof actions.createChart>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    // Pull the component order out the base response, and copy everything else.
    const chart = Object.assign(
      {
        chart_order: response.data.chart_order,
      },
      response.data.chart
    );

    //console.log("BBBB", response);

    chart.id = response.data.chart_id;

    //console.log("AAAA", chart);
    yield put(addChartLocal(chart));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createNavigation(
  action: ReturnType<typeof actions.createNavigation>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateNavigation,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    // Pull the component order out the base response, and copy everything else.
    const navigation = Object.assign(
      /*
      {
        chart_order: response.data.chart_order,
      },*/
      response.data.navigation
    );

    //console.log("BBBB", response);

    navigation.id = response.data.navigation_id;

    //console.log("AAAA", chart);
    yield put(addNavigationLocal(navigation));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createDataTable(
  action: ReturnType<typeof actions.createDataTable>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateDataTable,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    // Pull the component order out the base response, and copy everything else.
    const dataTable = Object.assign(
      /*
      {
        chart_order: response.data.chart_order,
      },*/
      response.data.data_table
    );

    //console.log("BBBB", response);

    dataTable.id = response.data.data_table_id;

    //console.log("AAAA", chart);
    yield put(addDataTableLocal(dataTable));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createApiCall(
  action: ReturnType<typeof actions.createApiCall>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateApiCall,
      userState.default_organization_id,
      dash,
      action.payload
    );

    // Pull the component order out the base response, and copy everything else.
    const apiCall = Object.assign(
      /*
      {
        chart_order: response.data.chart_order,
      },*/
      response.data.call
    );

    //console.log("BBBB", response);

    apiCall.id = response.data.api_call_id;

    //console.log("AAAA", chart);
    yield put(addApiCallLocal(apiCall));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}


export function* updateApiCall(
  action: ReturnType<typeof actions.updateApiCall>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const apiCallData = (yield select(
      selectEditingApiCall
    )) as ApiCall | null;

    if (apiCallData !== null) {
      const userState = (yield select(selectUser)) as User;
      const dash = (yield select(
        selectEditingDash
      )) as Dash | null;
      if (dash == null) throw "No dash is currently being edited";

      

      const response = yield call(
        apiUpdateApiCall,
        userState.default_organization_id,
        dash,
        action.payload,
        apiCallData.id,
      );

      // Pull the component order out the base response, and copy everything else.
      const apiCall = Object.assign(
        /*
        {
          chart_order: response.data.chart_order,
        },*/
        response.data.call
      );

      //console.log("BBBB", response);

      apiCall.id = response.data.api_call_id;

      //console.log("AAAA", chart);
      yield put(addApiCallLocal(apiCall));

      yield put(setStatus({ status: "idle", error: undefined }));
    }
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* executeApiCall(
  action: ReturnType<typeof actions.executeApiCall>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiExecuteApiCall,
      userState.default_organization_id,
      dash,
      action.payload
    );

    console.log(response.data.result);
    console.log(JSON.parse(response.data.result));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createDataTableColumn(
  action: ReturnType<typeof actions.createDataTableColumn>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dt = (yield select(selectEditingDataTable)) as DataTable;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateDataTableColumn,
      userState.default_organization_id,
      userState.default_workspace_id,
      dt.id,
      dash,
      action.payload
    );

    const response2 = yield call(
      apiGetDataTable,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash.id,
      dt.id,
    );

    // Send the data table to the frontend
    yield put(setDataTable(response2));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createDataTableRow(
  action: ReturnType<typeof actions.createDataTableRow>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dt = (yield select(selectEditingDataTable)) as DataTable;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateDataTableRow,
      userState.default_organization_id,
      userState.default_workspace_id,
      dt.id,
      dash,
      action.payload
    );

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createParameter(
  action: ReturnType<typeof actions.createParameter>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiCreateParameter,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    // Pull the component order out the base response, and copy everything else.
    const parameter = Object.assign(
      /*
      {
        chart_order: response.data.chart_order,
      },*/
      response.data.parameter
    );

    //console.log("BBBB", response);

    parameter.id = response.data.parameter_id;

    //console.log("AAAA", chart);
    yield put(addParameterLocal(parameter));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* attachChart(
  action: ReturnType<typeof actions.attachChart>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    const response = yield call(
      apiAttachChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload.sourceDocumentChartId,
      action.payload.componentOrder
    );
    console.log(response.data);

    // Pull the whole document to be safe.
    yield put(actions.setEditingDash(dash));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* updateChart(
  action: ReturnType<typeof actions.updateChart>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    //console.warn("TODO: Version/publish needed here");

    //console.log("C", action.payload);
    const response = yield call(
      apiUpdateChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    //console.log(response);

    const newChart = {...action.payload};
    //newSection.pending_heading = response.data.document_section.pending_heading;

    yield put(updateChartLocal(newChart));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* updateChartCode(
  action: ReturnType<typeof actions.updateChartCode>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {

    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    //console.warn("TODO: Version/publish needed here");

    //console.log("C", action.payload);
    const response = yield call(
      apiUpdateChartCode,
      action.payload.id,
      action.payload.code,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
    );

    // This is a quiet update

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* executeChartCode(
  action: ReturnType<typeof actions.executeChartCode>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {

    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    //console.warn("TODO: Version/publish needed here");

    //console.log("C", action.payload);

    const responseCode = yield call(
      apiUpdateChartCode,
      action.payload.chart.id,
      action.payload.code,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
    );
    
    const response = yield call(
      apiExecuteChartCode,
      action.payload.chart.id,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
    );

    //console.log("A", response);

    const newChart = {...action.payload.chart, code: action.payload.code, 
      code_result: response.data.code_result,
      code_error: response.data.code_error,
      updated_at: response.data.new_updated_at,
    };
    //newSection.pending_heading = response.data.document_section.pending_heading;

    yield put(updateChartLocal(newChart));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createChartAboveChart(
  action: ReturnType<typeof actions.createChartAboveChart>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  const belowChart = action.payload.belowChart,
    insertedChart = { ...action.payload.insertedChart };

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    // The sections should always be ordered already.

    // Get the index of the above section
    const belowIndex = dash.charts!.findIndex(
      (s) => s.id === belowChart.id
    );
    if (belowIndex == null) throw new Error("Cannot find below chart.");

    // If it's at the beginning, just append it.
    if (belowIndex === 0) {
      insertedChart.chart_order = belowChart.chart_order - 10;
      const response = yield call(
        apiCreateChart,
        userState.default_organization_id,
        userState.default_workspace_id,
        dash,
        insertedChart
      );
    } else {
      const newCharts = [...dash.charts!];

      //Set as the same component order and just update below.
      insertedChart.chart_order = belowChart.chart_order;
      const response = yield call(
        apiCreateChart,
        userState.default_organization_id,
        userState.default_workspace_id,
        dash,
        insertedChart
      );
      //TODO: Refactor this in future, should happen in API
      const insertedChartResp = Object.assign(
        {},
        response.data.document_section,
        { component_order: response.data.component_order }
      );

      newCharts.splice(belowIndex, 0, insertedChartResp);
      // Fix everything below.

      //const sectionsToUpdate = fixComponentOrderFromUpdatedElement(
      //  newCharts,
      //  belowIndex,
      //  insertedSection.component_order
      //);

      //for (const updatedSection of chartsToUpdate) {
      //  yield call(
      //    apiUpdateChart,
      //    userState.default_organization_id,
      //    userState.default_workspace_id,
      //    dash,
      //    updatedSection as Chart
      //  );
      //}
    }

    // Pull the whole document to be safe.
    yield put(actions.setEditingDash(dash));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* switchCharts(
  action: ReturnType<typeof actions.switchCharts>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  // Switch the component orders.
  const chartAUpdated = Object.assign({}, action.payload.sourceChart, {
      chart_order: action.payload.destinationChart.chart_order,
    }),
    chartBUpdated = Object.assign({}, action.payload.destinationChart, {
      chart_order: action.payload.sourceChart.chart_order,
    });

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No dash is currently being edited";

    //Switch the sections...
    const responseA = yield call(
      apiUpdateChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      chartAUpdated
    );
    const responseB = yield call(
      apiUpdateChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      chartBUpdated
    );

    // Pull the whole document to be safe.
    yield put(actions.setEditingDash(dash));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* deleteChart(
  action: ReturnType<typeof actions.deleteChart>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const dash = (yield select(
      selectEditingDash
    )) as Dash | null;
    if (dash == null) throw "No document is currently being edited";

    const response = yield call(
      apiDeleteChart,
      userState.default_organization_id,
      userState.default_workspace_id,
      dash,
      action.payload
    );

    yield put(deleteChartLocal(action.payload));
  
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}


/*
export function* uploadDocumentElementImage(
  action: ReturnType<typeof actions.uploadDocumentElementImage>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const importResponse = yield call(
      apiUploadDocumentElementImage,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload.section,
      action.payload.subSection,
      action.payload.documentElement,
      action.payload.file
    );

    console.error("Versioning is incorrect here");
    const newElement = Object.assign({}, action.payload.documentElement, {
      content: String(importResponse.data.document_image_id),
      versioned: false,
    });

    // Update the document element with the new ID.
    yield put(
      actions.updateDocumentElement({
        section: action.payload.section,
        subSection: action.payload.subSection,
        documentElement: newElement,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}
*/